//Specific JS for this skin
require(['jquery'], function ($) {
    $('#mini-cart .hide-below-960').html("<i class='fa fa-shopping-cart'></i>");
    $('.form-search .button-search').html("<i class='fa fa-search'></i>");

    if ($(".nav-search").length === 0) {
        $('.module-header-multi').append("<div class='show-below-960 item item-right nav-search'>" + $(".search-wrapper").html() + "</div>");
    }

    $(".nav-search .form-search .button.button-search").on("click", function (e) {
        if (!$(".nav-search").hasClass("fly-out")) {
            e.preventDefault();
            $(".nav-search").addClass("fly-out");
            $(".nav-search .form-search .input-text").focus();
            if (!$(".nav-search .form-search .button-close").length) $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
        }
    });

    $(document).on('click', '.nav-search .button-close', function (e) {
        e.preventDefault();
        $(".nav-search").removeClass("fly-out");
    });

    /* floating header */
    var halfScreenSize = ($(document).width() * 0.5);
    $(window).scroll(function () {
        $this = $(this);
        var topSize = 119;
        if ($this.width() <= 960) {
            topSize = 69;
        }
        if ($this.scrollTop() >= topSize) {
            if (!$('#header').hasClass('floating')) {
                $('body').css('padding-top', $('.header-primary-container').height());
                $('#header').addClass('floating');
                $('#header').slideDown('fast');
            }
        }
        if ($this.scrollTop() < topSize) {
            if ($('#header').hasClass('floating')) {
                $('body').attr('style', '');
                $('#header').removeClass('floating');
                $('#header').attr('style', '');
            }
        }

        if ($('button.btn-cart').length > 0) {
            var isVisibleOnScreen = isScrolledIntoView('button.btn-cart');
            if (isVisibleOnScreen) {
                $('.add-cart-fixed').hide();
                $('.add-cart-fixed').width('50%');
            } else {
                if ($('.add-cart-fixed').width() === halfScreenSize) {
                    $('.add-cart-fixed').show().animate({ width: '100%' });
                }
            }
        }
    });
});
